<template>
  <div class="wrapLogin">
    <div class="login">
      <div
        style="
          position: relative;
          min-width: 1052px;
          max-width: 1228px;
          left: 50%;
          transform: translate(-50%);
        "
      >
        <div class="login_left_img">
          <img src="../images/login_left_img.png" />
        </div>

        <div id="login_con" class="login_con">
          <div class="msg msgInfo">
            <div class="msgTitle">
              <p class="platformName">{{ obj.playName }}</p>
              <p
                :title="words"
                id="error"
                class="error errorInfo"
                v-show="markedWords"
              >
                {{ words }}
              </p>
            </div>
          </div>

          <el-form
            :model="ruleForm"
            ref="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm demo-ruleForm1"
          >
            <el-form-item
              prop="username"
              error
              class="usernameBox"
              style="margin-left: 0px"
            >
              <el-input
                type="text"
                v-model="ruleForm.username"
                @input="input"
                placeholder="请输入用户名"
                class="user"
              >
                <i slot="prefix" style="display: flex; align-items: center">
                  <img
                    class="header-icon"
                    style="
                      width: 19px;
                      height: 23px;
                      margin-top: 8px;
                      margin-left: 4px;
                    "
                    src="../images/icon_user.png"
                    alt=""
                  />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" error>
              <el-input
                type="password"
                v-model="ruleForm.password"
                @input="input"
                placeholder="请输入密码"
                auto-complete="new-password"
                class="pass-style"
              >
                <i slot="prefix" style="display: flex; align-items: center">
                  <img
                    class="header-icon"
                    style="
                      width: 19px;
                      height: 23px;
                      margin-top: 8px;
                      margin-left: 4px;
                    "
                    src="../images/icon_pwd.png"
                    alt=""
                  />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" error>
              <el-input
                type="text"
                placeholder="请输入验证码"
                v-model="ruleForm.code"
                @input="input"
                class="verify"
                @keypress.enter.native="goToLogin('ruleForm')"
              >
                <i slot="prefix" style="display: flex; align-items: center">
                  <img
                    class="header-icon"
                    style="
                      width: 19px;
                      height: 23px;
                      margin-top: 8px;
                      margin-left: 4px;
                    "
                    src="../images/icon_yzm.png"
                    alt=""
                  />
                </i>
              </el-input>
              <div class="captcha" @click="getCode">
                <img :src="verificationImg" class="verification" alt="验证码" />
              </div>
            </el-form-item>

            <el-form-item class="formItemBtn">
              <!-- <div style="height: 40px"> -->
              <el-button
                v-if="loginBtnShow"
                type="primary"
                @click="goToLogin('ruleForm')"
                class="loginBtn"
                style=""
                >登录</el-button
              >
              <!-- </div> -->
            </el-form-item>
            <el-form-item class="bottomMsg">
              <a @click="download" v-if="loginShow">  </a>
              <a @click="forgetClick" v-if="loginShow" style="float: right;">忘记密码?</a>
              <span @click="activeClick" v-if="!loginShow">系统激活</span>
            </el-form-item>
          </el-form>
          <div class="copyright_t suninfo">
            {{ this.copyText }}
          </div>
          <!-- /**下载移动应用弹框**/ -->
          <Eldialog
            :num="num"
            :width="'850px'"
            :title="'下载应用'"
            :dialogVisible="downApp"
            @handleClose="handleClose"
            @heightCustom="heightCustom"
          >
            <downPage></downPage>
          </Eldialog>
          <!-- 系统激活弹框 -->
          <Eldialog
            :num="num1"
            :width="'600px'"
            :title="'激活系统'"
            :dialogVisible="activeBox"
            @handleClose="handleClose"
            @heightCustom="heightCustom1"
            :flagbtn="true"
            :determineBtn="true"
            @determine="determine"
            :btnTitle="'激活'"
          >
            <!-- <activePage :childArr="childArray"></activePage> -->
            <div class="wrapBox">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <div
                  style="
                    height: 60px;
                    line-height: 60px;
                    display: flex;
                    margin-left: 15px;
                  "
                >
                  <div style="width: 87px; text-align: center">请求码：</div>
                  <el-popover
                    placement="top-start"
                    :title="ruleForm.requestCode"
                    popper-class="popover-self"
                    width="100%"
                    trigger="hover"
                  >
                    <!-- <el-button slot="reference">hover 激活</el-button> -->
                    <span
                      slot="reference"
                      style="
                        width: 454px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ ruleForm.requestCode }}
                    </span>
                  </el-popover>
                  <!-- <span style="
                      width: 454px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    {{ ruleForm.requestCode }}
                  </span> -->
                </div>
                <el-form-item label="序列号：" prop="snCode">
                  <el-input size="small" v-model="ruleForm.snCode"></el-input>
                </el-form-item>
                <el-form-item label="激活码：" prop="responseCode">
                  <el-input
                    size="small"
                    v-model="ruleForm.responseCode"
                  ></el-input>
                </el-form-item>
                <el-form-item style="height: 30px; margin-bottom: 0px">
                  <p style="color: red">请联系客服人员获取序列号与激活码</p>
                </el-form-item>
              </el-form>
            </div>
          </Eldialog>
          <!-- //重置密码弹框 -->
          <elDialog
            :title="'重置密码'"
            :width="'400px'"
            :num="'240px'"
            :btnTitle="'提交'"
            :dialogVisible="statusVisiblemm"
            @handleClose="handleClose"
            @heightCustom="heightCustomRest"
            :flagbtn="true"
            :determineBtn="true"
            @determine="determineRest"
          >
            <div style="text-align: center; height: 30px; padding: 5px 0px">
              <span
                class="error errorInfo"
                v-show="restWords"
                style="color: red"
                >{{ rest_words }}</span
              >
            </div>
            <el-form
              style="height: 90px; margin-top: 10px"
              :model="form"
              :rules="rules"
              ref="form"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="旧密码：" prop="oldPassword">
                <el-input
                  type="password"
                  @input="inputRestWords"
                  v-model="form.oldPassword"
                  clearable
                  size="mini"
                  class="passInputs"
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码：" prop="newPassword1">
                <el-input
                  type="password"
                  @input="inputRestWords"
                  v-model="form.newPassword1"
                  clearable
                  size="mini"
                  class="passInputs"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="newPassword2">
                <el-input
                  v-model="form.newPassword2"
                  @input="inputRestWords"
                  clearable
                  type="password"
                  size="mini"
                  class="passInputs"
                ></el-input>
              </el-form-item>
            </el-form>
          </elDialog>
        </div>
      </div>
    </div>
    <!-- /**短信认证**/ -->
    <ModalBox
      :num="msg_elDialg"
      :width="'1300px'"
      :dialogVisible="msgapp"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <loginmsg
        @backlogin="closeMages"
        @method="methodses"
        :detailDatas="detailData"
        :playName="playNameCom"
      ></loginmsg>
    </ModalBox>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleCloseInfo"
      @determine="determineInfo"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
history.pushState(null, null, document.URL);
window.addEventListener("popstate", function () {
  history.pushState(null, null, document.URL);
});
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
import utils from "../utils/index.js";
import axios from "axios";
import downPage from "./dwonAppCom.vue";
// import activePage from './activePage.vue'
import elDialog from "@/components/elDialog"; //详情弹框
import Eldialog from "../components/elDialog/index.vue"; //详情弹框
import ModalBox from "../components/elDialog/ModalBox.vue"; //模态框
import loginmsg from "../components/loginmsg.vue"; //短信验证
export default {
  components: { Eldialog, downPage, elDialog, dialogInfo, ModalBox, loginmsg },
  data() {
    const validateUser = (rule, value, callback) => {
      undefined;
      if (value === "") {
        undefined;

        callback(new Error("请输入用户名"));
      } else {
        undefined;

        if (!/^[0-9a-zA-Z_]{1,}$/.test(value)) {
          undefined;

          callback(new Error("请输入正确的用户名"));
        } else {
          undefined;

          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value === value) {
        callback();
      } else {
        callback(new Error("请输入正确的验证码"));
      }
    };
    return {
      detailData: {},
      playNameCom: "",
      address: vueConfig.thePathaddress,
      copyText: infoConfig.copyright, //底部版权归属
      lock: true,
      diffTime: "", //账号锁定时间
      titleInfo: "",
      visibaelFlag: false, //提示消息弹框默认不显示
      restWords: false,
      rest_words: "", //重置密码提示语
      statusVisiblemm: false, //修改密码谈框默认关闭
      // 轮循定时器
      timer: null,
      year: new Date().getFullYear(),
      obj: {
        playName: "", //平台名称
      },
      childArr: {
        snCode: "", //请求码
        requestCode: "", //序列号
      },
      num: "396px",
      num1: "250px",
      numRest: "174px",
      msg_elDialg: "100vh",
      loginBtnShow: true, //登录按钮显示或隐藏  默认隐藏
      loginShow: true, //系统激活按钮显示或隐藏  默认隐藏
      verificationImg:
        vueConfig.thePathaddress + "/httpManageExport/dynamicCode",
      downApp: false, //下载移动应用弹框  默认关闭
      msgapp: false, //  默认关闭
      activeBox: false, //激活系统弹框  默认关闭
      // 账号和密码提示是否显示
      markedWords: false,

      isCode: false,
      words: "", //错误提示语
      ruleForm: {
        username: "",
        password: "",
        code: "",
        snCode: "", //请求码
        requestCode: "", //序列号
        responseCode: "", //激活码
        methodss: 0, //短信认证带过来
      },
      form: {
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      rules: {
        username: [
          {
            validator: validateUser,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: validatePass,
            trigger: "blur",
          },
          // {
          //   min: 1,
          //   max: 16,
          //   message: '密码长度必须是1~6位数字！',
          //   trigger: 'blur'
          // }
        ],
        code: [
          {
            validator: validateCode,
            trigger: "blur",
          },
          // { require: true, message: '验证码不能为空', trigger: 'blur' },
          // { min: 4, max: 4, message: '长度是5位的字母或数字', trigger: 'blur' }
        ],
        requestCode: [
          { required: true, message: "请输入序列号", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        responseCode: [
          { required: true, message: "请输入激活码", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            min: 8,
            max: 32,
            message: "8~32位，至少包含数字、字母、符号两种元素。",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (
                /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,32}/.test(value) ==
                false
              ) {
                callback(
                  new Error("8~32位，至少包含数字、字母、符号两种元素。")
                );
              } else if (value == "") {
                callback(new Error("请输入旧密码"));
              } else {
                callback();
              }
            },
            trigger: "change", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        newPassword1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 32,
            message: "8~32位，至少包含数字、字母、符号两种元素。",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (
                /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,32}/.test(value) ==
                false
              ) {
                callback(
                  new Error("8~32位，至少包含数字、字母、符号两种元素。")
                );
              } else if (
                this.form.oldPassword !== "" &&
                value == this.form.oldPassword
              ) {
                callback(new Error("新密码不能和旧密码一致"));
              } else if (value == "") {
                callback(new Error("请输入密码"));
              } else {
                callback();
              }
            },
            trigger: "change", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 8,
            max: 32,
            message: "8~32位，至少包含数字、字母、符号两种元素。",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请输入确认密码"));
              } else if (
                /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,32}/.test(value) ==
                false
              ) {
                callback(
                  new Error("8~32位，至少包含数字、字母、符号两种元素。")
                );
              } else if (
                this.form.newPassword1 !== "" &&
                value !== this.form.newPassword1
              ) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            },
            trigger: "blur", //这里还有blur触发方法； 如果设为none，那么只有this.$refs[formName]['validate']时才会验证
          },
        ],
      },
    };
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    this.getCode();
    this.activeSystem();
    let dataCode = this.guid();
    sessionStorage.setItem("confirm", dataCode);
    this.verificationImg =
      this.address + "/httpManageExport/dynamicCode?code=" + dataCode;

    // var t3 = '2022-06-06 20:15:00'
    // debugger
    // this.timeFn(t3)
    // console.log(this.timeFn(t3), 'timeFn(t3)')
  },
  created() {
    this.createSetInterval(); //开启定时器
  },

  methods: {
    closeMages(data) {
      if (data == 1) {
        this.msgapp = false; //点击详情弹框关闭
        this.ruleForm.password = "";
        this.ruleForm.code = "";
        this.getCode(); //再次获取验证码
      } else if (data == -1) {
        this.getUserInfo();
      }
    },
    methodses(data) {
      console.log(data, "datadatadata");
      this.ruleForm.methodss = data.method;
      this.ruleForm.uuid = data.uuid;
      

    },
    //传入的分钟数  转换成天、时、分
    timeStamp(StatusMinute) {
      var day = parseInt(StatusMinute / 60 / 24);
      var hour = parseInt((StatusMinute / 60) % 24);
      var min = parseInt(StatusMinute % 60);
      StatusMinute = "";
      if (day > 0) {
        StatusMinute = day + "天";
      }
      if (hour > 0) {
        StatusMinute += hour + "小时";
      }
      if (min > 0) {
        StatusMinute += parseFloat(min) + "分钟";
      }
      //三元运算符 传入的分钟数不够一分钟 默认为0分钟，else return 运算后的StatusMinute
      return StatusMinute == "" ? "0分钟" : StatusMinute;
    },
    // 开启轮询  如果存在则先销毁定时器后重新开启
    createSetInterval() {
      this.stopSetInterval();
      // let _this = this
      this.timer = setInterval(() => {
        ///调取接口获取数据
        this.getCode();
      }, 60000);
    },
    // 关闭轮询
    stopSetInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    //用户输入时提示语不显示
    input() {
      this.markedWords = false;
    },
    inputRestWords() {
      this.restWords = false;
    },
    // 点击切换验证码
    async getCode() {
      let dataCode = this.guid();
      sessionStorage.setItem("confirm", dataCode);
      this.verificationImg =
        this.address + "/httpManageExport/dynamicCode?code=" + dataCode;
    },
    // 登录
    async getUserInfo() {
      let confirmData = sessionStorage.getItem("confirm");
      console.log(confirmData, "confirmData");
      var params = {
        userName: this.ruleForm.username,
        passWord: utils.common.encrypt(
          this.ruleForm.password,
          "SM4",
          $.getSecretKey(this.ruleForm.username) //基于用户名的密钥
        ),
        code: this.ruleForm.code,
        newPassword: "",
        method: this.ruleForm.methodss,
        uuid: this.ruleForm.uuid,
      };
      sessionStorage.setItem("udidSign", this.ruleForm.uuid);
      let url = vueConfig.thePathaddress + "/httpServe/login";
      axios
        .post(url, params, {
          headers: {
            confirm: confirmData,
          },
        })
        .then((res) => {
          console.log(params, "methodmethodmethodmethod");
          console.log(res, "登录信息");
          if (res.data.code == 1) {
            this.markedWords = true;
            this.words = res.data.message;
          }
          if (res.data.code == 3) {
            this.markedWords = true;
            this.words = "用户名或密码错误";
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 10) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words =
                "用户名或密码错误，还可以输入" + res.data.message + "次";
              this.getCode(); //再次获取验证码
            }
          }
          if (res.data.code == 11) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words = "用户名密码AD域校验失败";
            }
          }
          if (res.data.code == 12) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words = "用户名密码LDAP域校验失败";
            }
          }
          if (res.data.code == 13) {
            this.$nextTick(() => {
              this.markedWords = true;
              const t3 = res.data.message;
              this.words =
                "错误次数超过限制，请在" + this.timeStamp(t3) + "后再试";
            });
          }
          if (res.data.code == 14) {
            this.markedWords = true;
            this.words = "用户已停用，请联系管理员";
          }
          // 30-邮件验证码校验失败 31-邮件验证码不存在（或已过期）------------暂未开发
          if (res.data.code == 5) {
            this.markedWords = true;
            this.words = "验证码有误";
            this.getCode(); //再次获取验证码
          }
          // debugger
          if (res.data.code == 200) {
            sessionStorage.setItem("groupId", res.data.data.groupId);
            sessionStorage.setItem(
              "groupFullNameShowByIndex",
              res.data.data.groupFullNameShowByIndex
            );
            sessionStorage.setItem("roleId", res.data.data.roleId);
            sessionStorage.setItem(
              "Authorization",
              res.data.data.authorization
            );
            sessionStorage.setItem(
              "manageGroupId",
              res.data.data.manageGroupId
            );
            sessionStorage.setItem(
              "manageGroupFullName",
              res.data.data.manageGroupFullName
            );
            sessionStorage.setItem("userId", res.data.data.userId);
            sessionStorage.setItem("userName", res.data.data.loginName);
            sessionStorage.setItem("oo", 1);
            if (res.data.data.roleId == 1) {
              this.$router.push("/SourceHome");
            } else if (res.data.data.roleId == 2) {
              this.$router.push("/ManageLog");
            } else if (res.data.data.roleId == 3) {
              this.$router.push("/Running");
            } else if (res.data.data.roleId == 4) {
              this.$router.push("/equipmentManagement");
            } else if (
              res.data.data.roleId != 1 ||
              res.data.data.roleId != 2 ||
              res.data.data.roleId != 3 ||
              res.data.data.roleId != 4
            ) {
              this.$router.push("/SourceHome");
            }
            // 登录成功关闭验证码轮询
            this.stopSetInterval();
          }
          if (res.data.code == 500) {
            this.markedWords = true;
            this.words = res.data.message;
          }
        })
        .catch(() => {
          console.log("error submit!!");
        });
    },
    // 登录
    async authlogin() {
      let confirmData = sessionStorage.getItem("confirm");
      console.log(confirmData, "confirmData");
      var params = {
        user: this.ruleForm.username,
        pass: utils.common.encrypt(
          this.ruleForm.password,
          "SM4",
          $.getSecretKey(this.ruleForm.username) //基于用户名的密钥
        ),
        code: this.ruleForm.code,
        newPassword: "",
        client: "web",
        method: 0, //第二次重新获取
        confirm: confirmData,
      };
      let url = vueConfig.thePathaddress + "/httpServe/rest/v6/get/user/auth";
      axios
        .post(url, params)
        .then((res) => {
          if (res.data.code == 1) {
            this.markedWords = true;
            this.words = res.data.message;
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 3) {
            this.markedWords = true;
            this.words = "用户名或密码错误";
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 4) {
            this.markedWords = true;
            this.words = "该IP已锁定，请联系管理员";
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 5) {
            this.markedWords = true;
            this.words = "验证码有误";
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 10) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words =
                "用户名或密码错误，还可以输入" + res.data.message + "次";
              this.getCode(); //再次获取验证码
            }
          }
          if (res.data.code == 11) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words = "用户名密码AD域校验失败";
            }
          }
          if (res.data.code == 12) {
            if (res.data.message == -1) {
              this.markedWords = true;
              this.words = "用户名或密码错误";
              this.getCode(); //再次获取验证码
            } else {
              this.markedWords = true;
              this.words = "用户名密码LDAP域校验失败";
            }
          }
          if (res.data.code == 13) {
            this.$nextTick(() => {
              this.markedWords = true;
              const t3 = res.data.message;
              this.words =
                "错误次数超过限制，请在" + this.timeStamp(t3) + "后再试";
            });
            this.getCode(); //再次获取验证码
          }
          if (res.data.code == 14) {
            this.markedWords = true;
            this.words = "用户已停用，请联系管理员";
            this.getCode(); //再次获取验证码
          }
          // 30-邮件验证码校验失败 31-邮件验证码不存在（或已过期）------------暂未开发
          this.ruleForm.uuid = res.data.data.uuid;
          console.log(this.ruleForm.uuid);
          res.data.data.user = this.ruleForm.username;
          this.detailData = res.data.data;
          console.log(this.detailData, "this.detailDatathis.detailData");
          if (res.data.data.initial == 0) {
            if (
              res.data.data.errorCode != 10 ||
              res.data.data.errorCode != 13
            ) {
              this.statusVisiblemm = true; //修改密码
              this.$set(this.form, "oldPassword", "p@ssw0rd"); //清空oldPassword属性值
              setTimeout(() => {
                this.$nextTick(() => {
                  this.$set(this.form, "oldPassword", "");
                  this.$refs["form"].resetFields();
                });
              }, 100);
              this.$set(this.form, "oldPassword", "");
              this.$set(this.form, "newPassword1", ""); //清空newPassword1属性值
              this.$set(this.form, "newPassword2", ""); //清空newPassword2属性值
            }
            return false;
          }
          if (res.data.data.method == -1) {
            // 重新进入login页面
            this.getUserInfo();
            return false;
          }
          if (res.data.data.method == 1) {
            console.log(this.msgapp, "this.msgappthis.msgapp");
            // 短信验证
            window.history.forward(1);
            this.msgapp = true;
            return false;
          }
          if (res.data.code == 5) {
            this.markedWords = true;
            this.words = "验证码有误";
            this.getCode(); //再次获取验证码
          }
          // debugger
          if (res.data.code == 200) {
            sessionStorage.setItem("groupId", res.data.data.groupId);
            sessionStorage.setItem(
              "groupFullNameShowByIndex",
              res.data.data.groupFullNameShowByIndex
            );
            sessionStorage.setItem("roleId", res.data.data.roleId);
            sessionStorage.setItem(
              "Authorization",
              res.data.data.authorization
            );
            sessionStorage.setItem(
              "manageGroupId",
              res.data.data.manageGroupId
            );
            sessionStorage.setItem(
              "manageGroupFullName",
              res.data.data.manageGroupFullName
            );
            sessionStorage.setItem("userName", res.data.data.loginName);
            sessionStorage.setItem("oo", 1);
            // sessionStorage.setItem('userId', res.data.data.userId)
            // if (res.data.data.roleId == 1) {
            //   this.$router.push('/SourceHome')
            // } else if (res.data.data.roleId == 2) {
            //   this.$router.push('/ManageLog')
            // } else if (res.data.data.roleId == 3) {
            //   this.$router.push('/Running')
            // } else if (
            //   res.data.data.roleId != 1 ||
            //   res.data.data.roleId != 2 ||
            //   res.data.data.roleId != 3
            // ) {
            //   this.$router.push('/login')
            // }
            // 登录成功关闭验证码轮询
            this.stopSetInterval();
          }
          if (res.data.code == 500) {
            this.markedWords = true;
            this.words = res.data.message;
          }
        })
        .catch(() => {
          console.log("error submit!!");
        });
    },
    goToLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(111);
          if (this.loginBtnShow) {
            // this.getUserInfo()
            this.authlogin();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //确定重置密码验证
    resetPass(form) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证通过保存数据
          this.confirmPass();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //点击确定修改密码
    async confirmPass() {
      const res = await this.$axios.post(
        "/httpServe/changePwd",
        {
          userName: this.ruleForm.username,
          passWord: utils.common.encrypt(
            this.form.oldPassword,
            "SM4",
            $.getSecretKey(this.ruleForm.username)
          ),
          newPassword: utils.common.encrypt(
            this.form.newPassword2,
            "SM4",
            $.getSecretKey(this.ruleForm.username)
          ),
        },
        true
      );
      if (res.code == 500) {
        this.restWords = true;
        this.rest_words = "修改密码失败";
      } else if (res.code == 200) {
        if (res.data.status == 1) {
          this.$refs.form.clearValidate();
          this.$message({
            message: "修改密码成功",
            type: "success",
            offset: 100,
          });
          this.$set(this.form, "oldPassword", ""); //清空oldPassword属性值
          this.$set(this.form, "newPassword1", ""); //清空newPassword1属性值
          this.$set(this.form, "newPassword2", ""); //清空newPassword2属性值
          this.statusVisiblemm = false;
          this.$refs["form"].resetFields();
          this.getCode(); //再次获取验证码
        } else if (res.data.status == 2) {
          this.$message({
            message: "旧密码错误",
            type: "error",
            offset: 100,
          });
        } else if (res.data.status == 3) {
          this.$message({
            message: "修改次数超限",
            type: "error",
            offset: 100,
          });
        }
      }
    },
    // 点击下载移动应用弹框出现
    download() {
      this.downApp = true; //点击出现详情弹框
    },
    // 弹框点击关闭事件
    handleClose() {
      this.downApp = false; //点击详情弹框关闭
      this.msgapp = false; //点击详情弹框关闭
      this.activeBox = false; //系统激活弹框关闭
      this.statusVisiblemm = false; //修改密码
      this.$set(this.form, "oldPassword", ""); //清空oldPassword属性值
      this.$set(this.form, "newPassword1", ""); //清空newPassword1属性值
      this.$set(this.form, "newPassword2", ""); //清空newPassword2属性值
      this.$refs["form"].resetFields();
    },
    //提示信息
    handleCloseInfo() {
      this.visibaelFlag = false; //提示消息弹框关闭
    },
    // 高度
    heightCustom() {
      this.height.height = this.msg_elDialg;
    },
    heightCustom1() {
      this.height.height = this.num1;
    },
    heightCustomRest() {
      this.height.height = this.numRestd;
    },
    //点击忘记密码
    forgetClick() {
      this.$router.push("/forgetPwd");
    },
    //激活系统
    activeSystem() {
      var params = {};
      this.$axios
        .get("/httpServe/load", params)
        .then((res) => {
          this.$nextTick(() => {
            // dom元素更新后执行，因此这里能正确打印更改之后的值
            this.obj.playName = res.data.data?.platformName;
            this.playNameCom = res.data.data?.platformName;
            this.ruleForm.snCode = res.data.data?.sn;
            this.ruleForm.requestCode = res.data.data?.requestCode;
          });
          if (res.data.data?.active) {
            this.loginBtnShow = true;
            this.loginShow = true;
          } else {
            this.loginBtnShow = false;
            this.loginShow = false;
          }
        })
        .catch(() => {
          this.loginBtnShow = false;
          this.loginShow = false;
        });
    },
    //系统激活弹框
    activeClick() {
      this.activeBox = true; //系统激活弹框显示
    },
    determine() {
      // this.activeBox = false //系统激活弹框关闭
      this.startBtn(); //确认激活调用接口
    },
    determineRest() {
      this.resetPass(); //重置密码
    },
    //提示信息
    determineInfo() {
      this.visibaelFlag = false;
    },
    //用户激活接口
    async startBtn() {
      var params = {
        sn: this.ruleForm.snCode, //序列号
        requestCode: this.ruleForm.requestCode, //请求码
        responseCode: this.ruleForm.responseCode,
      };
      const res = await this.$axios.post(
        "/httpServe/license/active",
        params,
        true
      );
      if (res.data == 1) {
        this.activeBox = false; //系统激活弹框关闭
        // this.$router.push('/login')
        // this.loginBtnShow = true //登录按钮显示
        // this.loginShow = false //系统激活按钮显示
        this.$router.go(0);
      }
      console.log(res, "激活功能");
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    this.stopSetInterval();
  },
};
</script>

<style lang="scss" scoped>
.pass-style {
  ::v-deep .el-input__inner {
    -webkit-text-security: disc !important;
  }
}
//1920分辨率
@media screen and (min-width: 1920px) and (max-width: 2047px) {
  .wrapLogin {
    width: 100%;
    height: 100vh;
    background: url(../images/body-bg.png) center 0px no-repeat;
    background-size: 100% 100%;
    // background: #0e78d5 url(../images/body-bg.png) center 0px no-repeat;
  }
  .login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 520px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .login .login_left_img {
    display: inline-block;
    float: left;
    margin-top: -41px;
  }
  .login .login_left_img img {
    width: 600px;
    height: 600px;
    min-width: 600px;
  }
  .login_con {
    width: 374px;
    height: 430px;
    margin: 37px auto 0;
    background: url(../images/login_bg.png) center center no-repeat;
    background-size: contain;
    position: relative;
    float: right;
    min-width: 450px;
    display: inline-block;
  }
  .login_con .msgInfo {
    text-align: center;
    width: 450px;
  }
  .login_con .msgTitle {
    height: 62px;
    width: 330px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%);
  }
  .msgInfo .platformName {
    display: inline-block;
    font-size: 25px;
    color: #3e4e69;
    overflow: hidden;
  }
  .demo-ruleForm1 {
    width: 280px;
    margin-left: 83px;
    margin-top: 110px;
  }
  .msgInfo .errorInfo {
    color: red !important;
    font-size: 12px;
  }
  .demo-ruleForm1 {
    .el-form-item__content {
      width: 100%;
      display: flex;
      margin: 0px !important;
      .el-input__inner {
        padding-left: 34px;
        border: 1px solid #e0e0e0;
      }
    }
    .usernameBox {
      ::v-deep .el-form-item__content {
        margin-left: 0px !important;
      }
    }
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
    .verify {
      width: 170px;
      float: left;
      height: 40px !important;
    }
    .captcha {
      width: 103px !important;
      height: 38px !important;
      text-align: justify;
      float: right;
      .verification {
        cursor: pointer;
        border: 0;
        border-radius: 0px;
        font-size: 18px;
        border-radius: 0px;
        width: 100px;
        outline: none;
        letter-spacing: 5px;
        height: 40px !important;
        transform: translateY(0px);
      }
    }
  }
  .loginbutton {
    width: 280px;
    height: 38px;
    font-size: 17px;
    background: #1e89e5;
    border-radius: 6px;
  }
  .bottomMsg {
    margin-top: -10px;
  }
  .bottomMsg a {
    color: #1e89e5;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  .bottomMsg a:nth-of-type(1) {
    margin-right: 153px;
  }
  .bottomMsg span {
    color: #909294;
    cursor: pointer;
    float: right;
  }
  .copyright_t {
    width: 100%;
    height: 40px;
    text-align: center;
    color: #acddff;
    position: absolute;
    bottom: -23px;
    font-family: Microsoft YaHei;
    font-size: 14px;
  }
  .formItemBtn {
    margin-bottom: 14px !important;
  }
  .loginBtn {
    height: 38px;
    font-size: 17px;
    width: 100%;
    border-radius: 6px;
  }
}
//1680分辨率
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .wrapLogin {
    width: 100%;
    height: 100vh;
    background: url(../images/body-bg1650x1050.png) center 0px no-repeat;
    background-size: 100% 100%;
    // background: #0e78d5 url(../images/body-bg.png) center 0px no-repeat;
  }
  .login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 520px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .login .login_left_img {
    display: inline-block;
    float: left;
    margin-top: -41px;
  }
  .login .login_left_img img {
    width: 600px;
    height: 600px;
    min-width: 600px;
  }
  .login_con {
    width: 374px;
    height: 430px;
    margin: 37px auto 0;
    background: url(../images/login_bg.png) center center no-repeat;
    background-size: contain;
    position: relative;
    float: right;
    min-width: 450px;
    display: inline-block;
  }
  .login_con .msgInfo {
    text-align: center;
    width: 450px;
  }
  .login_con .msgTitle {
    height: 62px;
    width: 330px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%);
  }
  .msgInfo .platformName {
    display: inline-block;
    font-size: 25px;
    color: #3e4e69;
    overflow: hidden;
  }
  .demo-ruleForm1 {
    width: 280px;
    margin-left: 83px;
    margin-top: 110px;
  }
  .msgInfo .errorInfo {
    color: red !important;
    font-size: 12px;
  }
  .demo-ruleForm1 {
    .el-form-item__content {
      width: 100%;
      display: flex;
      margin: 0px !important;
      .el-input__inner {
        padding-left: 34px;
        border: 1px solid #e0e0e0;
      }
    }
    .usernameBox {
      ::v-deep .el-form-item__content {
        margin-left: 0px !important;
      }
    }
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
    .verify {
      width: 170px;
      float: left;
      height: 40px !important;
    }
    .captcha {
      width: 103px !important;
      height: 38px !important;
      text-align: justify;
      float: right;
      .verification {
        cursor: pointer;
        border: 0;
        border-radius: 0px;
        font-size: 18px;
        border-radius: 0px;
        width: 100px;
        outline: none;
        letter-spacing: 5px;
        height: 40px !important;
        transform: translateY(0px);
      }
    }
  }
  .loginbutton {
    width: 280px;
    height: 38px;
    font-size: 17px;
    background: #1e89e5;
    border-radius: 6px;
  }
  .bottomMsg {
    margin-top: -10px;
  }
  .bottomMsg a {
    color: #1e89e5;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  .bottomMsg a:nth-of-type(1) {
    margin-right: 153px;
  }
  .bottomMsg span {
    color: #909294;
    cursor: pointer;
    float: right;
  }
  .copyright_t {
    width: 100%;
    height: 40px;
    text-align: center;
    color: #acddff;
    position: absolute;
    bottom: -23px;
    font-family: Microsoft YaHei;
    font-size: 14px;
  }
  .formItemBtn {
    margin-bottom: 14px !important;
  }
  .loginBtn {
    height: 38px;
    font-size: 17px;
    width: 100%;
    border-radius: 6px;
  }
}
@media screen and (max-width: 1680px) {
  .wrapLogin {
    width: 100%;
    height: 100vh;
    background: url(../images/body-bg1650x1050.png) center 0px no-repeat;
    background-size: 100% 100%;
    // background: #0e78d5 url(../images/body-bg.png) center 0px no-repeat;
  }
  .login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 520px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .login .login_left_img {
    display: inline-block;
    float: left;
    margin-top: -41px;
  }
  .login .login_left_img img {
    width: 600px;
    height: 600px;
    min-width: 600px;
  }
  .login_con {
    width: 374px;
    height: 430px;
    margin: 37px auto 0;
    background: url(../images/login_bg.png) center center no-repeat;
    background-size: contain;
    position: relative;
    float: right;
    min-width: 450px;
    display: inline-block;
  }
  .login_con .msgInfo {
    text-align: center;
    width: 450px;
  }
  .login_con .msgTitle {
    height: 62px;
    width: 330px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%);
  }
  .msgInfo .platformName {
    display: inline-block;
    font-size: 25px;
    color: #3e4e69;
    overflow: hidden;
  }
  .demo-ruleForm1 {
    width: 280px;
    margin-left: 83px;
    margin-top: 110px;
  }
  .msgInfo .errorInfo {
    color: red !important;
    font-size: 12px;
  }
  .demo-ruleForm1 {
    .el-form-item__content {
      width: 100%;
      display: flex;
      margin: 0px !important;
      .el-input__inner {
        padding-left: 34px;
        border: 1px solid #e0e0e0;
      }
    }
    .usernameBox {
      ::v-deep .el-form-item__content {
        margin-left: 0px !important;
      }
    }
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
    .verify {
      width: 170px;
      float: left;
      height: 40px !important;
    }
    .captcha {
      width: 103px !important;
      height: 38px !important;
      text-align: justify;
      float: right;
      .verification {
        cursor: pointer;
        border: 0;
        border-radius: 0px;
        font-size: 18px;
        border-radius: 0px;
        width: 100px;
        outline: none;
        letter-spacing: 5px;
        height: 40px !important;
        transform: translateY(0px);
      }
    }
  }
  .loginbutton {
    width: 280px;
    height: 38px;
    font-size: 17px;
    background: #1e89e5;
    border-radius: 6px;
  }
  .bottomMsg {
    margin-top: -10px;
  }
  .bottomMsg a {
    color: #1e89e5;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  .bottomMsg a:nth-of-type(1) {
    margin-right: 153px;
  }
  .bottomMsg span {
    color: #909294;
    cursor: pointer;
    float: right;
  }
  .copyright_t {
    width: 100%;
    height: 40px;
    text-align: center;
    color: #acddff;
    position: absolute;
    bottom: -23px;
    font-family: Microsoft YaHei;
    font-size: 14px;
  }
  .formItemBtn {
    margin-bottom: 14px !important;
  }
  .loginBtn {
    height: 38px;
    font-size: 17px;
    width: 100%;
    border-radius: 6px;
  }
}
//2560分辨率
@media screen and (min-width: 2048px) and (max-width: 2560px) {
  .wrapLogin {
    width: 100%;
    height: 100vh;
    background: url(../images/body-bg25601440.png) center 0px no-repeat;
    background-size: 100% 100%;
    // background: #0e78d5 url(../images/body-bg.png) center 0px no-repeat;
  }
  .login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 760px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .login .login_left_img {
    display: inline-block;
    float: left;
    margin-top: -41px;
    margin-left: -122px;
  }
  .login .login_left_img img {
    width: 760px;
    height: 760px;
    min-width: 760px;
  }
  .login_con {
    width: 409px;
    height: 478px;
    margin: 67px auto 0;
    background: url(../images/login_bg.png) center center no-repeat;
    background-size: contain;
    position: relative;
    float: right;
    min-width: 450px;
    display: inline-block;
    margin-right: -86px;
  }
  .login_con .msgInfo {
    text-align: center;
    width: 450px;
  }
  .login_con .msgTitle {
    height: 62px;
    width: 330px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%);
  }
  .msgInfo .platformName {
    display: inline-block;
    font-size: 32px;
    color: #3e4e69;
    overflow: hidden;
  }
  .demo-ruleForm1 {
    width: 320px;
    margin-left: 62px;
    margin-top: 120px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 30px;
  }
  ::v-deep .el-form-item__error {
    font-size: 16px;
  }
  .msgInfo .errorInfo {
    color: red !important;
    font-size: 12px;
  }
  .demo-ruleForm1 {
    .el-form-item__content {
      width: 100%;
      display: flex;
      margin: 0px !important;
      .el-input__inner {
        padding-left: 34px;
        border: 1px solid #e0e0e0;
      }
    }
    .usernameBox {
      ::v-deep .el-form-item__content {
        margin-left: 0px !important;
      }
    }
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
    .verify {
      width: 186px;
      float: left;
      height: 40px !important;
    }
    .captcha {
      width: 122px !important;
      height: 38px !important;
      text-align: justify;
      float: right;
      .verification {
        cursor: pointer;
        border: 0;
        border-radius: 0px;
        font-size: 18px;
        border-radius: 0px;
        width: 122px;
        outline: none;
        letter-spacing: 5px;
        height: 40px !important;
        transform: translateY(0px);
      }
    }
  }
  .loginbutton {
    width: 320px;
    height: 44px;
    font-size: 22px;
    background: #1e89e5;
    border-radius: 6px;
  }
  // .bottomMsg {
  //   margin-top: -10px;
  // }
  .bottomMsg a {
    color: #1e89e5;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
  }
  .bottomMsg a:nth-of-type(1) {
    margin-right: 162px;
  }
  .bottomMsg span {
    color: #909294;
    cursor: pointer;
    float: right;
  }
  .copyright_t {
    width: 100%;
    height: 34px;
    text-align: center;
    color: #acddff;
    position: absolute;
    bottom: -23px;
    font-family: Microsoft YaHei;
    font-size: 16px;
  }
  .formItemBtn {
    margin-bottom: 14px !important;
  }
  .loginBtn {
    height: 38px;
    font-size: 17px;
    width: 100%;
    border-radius: 6px;
  }
}
.msgInfo .errorInfo {
  color: red !important;
  font-size: 12px;
}

/*****-------------------******/

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 40px;
}
</style>
