<template>
  <el-dialog
    v-dialogDrag
    :num="num"
    :width="width"
    :flagbtn="flagbtn"
    :append-to-body="true"
    :visible="dialogVisible"
    style="margin-top: 0%"
    :before-close="handleClose"
    :determineBtn="determineBtn"
    :cancelBtn="cancelBtn"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  props: {
    btnTitle: {
      type: String,
      default: "确定",
    },
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "详情",
    },
    width: {
      type: String,
      default: "655px",
    },
    // 默认469px
    num: {
      type: String,
      default: "",
    },
    flagbtn: {
      //是否开启确定取消
      type: Boolean,
      default: false,
    },
    determineBtn: {
      //确定展示
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      //取消展示
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: {
        height: "",
      },
    };
  },
  created() {
    this.heightCustom();
  },
  watch: {
    dialogVisible(val) {
      this.dialogVisible = val;
    },
  },
  methods: {
    heightCustom() {
      this.height.height = this.num;
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
    determine() {
      // 确定回调事件
      this.$emit("determine", false);
    },
    cancel() {
      this.$emit("handleClose", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-corn {
  overflow: auto;
  scrollbar-width: none;
  // overflow-y: hidden;
}
::v-deep .dialog-footer {
  height: 0px !important;
}
::v-deep .el-dialog { // 取消阴影和背景色

background: rgba(0, 0, 0, 0);

-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

}
::v-deep .el-dialog__body{
  padding:0px 0px !important;
}
::v-deep  .el-dialog__header {
  padding:0px 0px 0px 0px !important;
}
</style>
