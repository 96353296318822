<template>
  <div>
    <div class="loginDownWrap">
      <el-row>
        <el-col :span="24">
          <div class="box">
            <!-- <img src="../images/app/default/appCut.png" alt=""> -->
            <!-- initQCode: 是你在你的vue实例中定义好的变量   size:是这个Canvas的大小，这里要根据设计图大小来决定-->
            <qriously :value="baseUrl" :size="200" />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <div class="box">
            <span id="downApk" @click="downApk" class="sunInfo">Android应用</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="box">
            <span id="downIpa" @click="downIpa" class="sunInfo">iOS应用</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="box">
            <span id="downWin" @click="downWin" class="sunInfo">Windows应用</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <span id="arm64" @click="LinuxArm64" class="sunInfo">Linux Arm应用</span>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="box">
            <span id="x86" @click="LinuxX86" class="sunInfo">Linux X86应用</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: '',
      isAuth:vueConfig.isAuth,//自认证值
      appUrl: vueConfig.APPUrl + 'rest/v5/get/mobile/apk',
      iosUrl: vueConfig.APPUrl + 'rest/v5/get/mobile/ipa', 
      windowsUrl: vueConfig.APPUrl + 'rest/v6/get/pc/exe', 
    }
  },
  // beforeCreate(){
  //   console.log(this.appUrl,'--appUrl--')
  //   console.log(this.iosUrl,'--iosUrl--')
  // },
  mounted() { 
    if(this.isAuth == true){
      // this.baseUrl = vueConfig.APPUrl + '/httpServe/rest/v6/get/user/auth'  //我的自认证页面
      this.baseUrl = vueConfig.jqUrl +'h5/download/downloadAuth'  //我的自认证页面  
      }else{
        this.baseUrl = vueConfig.APPUrl + 'rest/v5/get/mobile/download'
      }
    },
      methods: {
    // Android应用下载
    downApk() {
      window.open(vueConfig.APPUrl + 'rest/v5/get/mobile/apk')
    },
    // iOS应用下载
    downIpa() {
      window.open(vueConfig.APPUrl + 'rest/v5/get/mobile/ipa')
    },
    // Windows应用下载
    downWin() {
      window.open(vueConfig.APPUrl + 'rest/v6/get/pc/exe')
    },
    // Linux Arm64应用应用下载
    LinuxArm64() {
      window.open(vueConfig.APPUrl + 'rest/v6/get/pc/tar?arch=arm64')
    },
    // Linux X86应用下载
    LinuxX86() {
      window.open(vueConfig.APPUrl + 'rest/v6/get/pc/tar?arch=x86')
    }
  }
}
</script>

<style lang="scss" scoped>
// text-decoration:underline

.sunInfo {
  cursor: pointer;
  color: #1e89e5;
  font-size: 20px;
  text-decoration: underline;
}
.box {
  margin-top: 60px;
  text-align: center;
}
</style>
