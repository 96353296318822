<template>
  <el-row class="msg_bg">
    <el-col :span="12">
      <div class="msg_left_title">
        <p>欢 迎 使 用</p>
        <br />
        <p>{{ playName }}</p>
        <!-- <p>移 动 安 全 管 理 平 台</p> -->
      </div>
    </el-col>
    <el-col :span="12">
      <!-- :rules="rules" -->
      <!-- {{ detailDatas }} -->
      <el-form :model="ruleForm" ref="ruleForm" class="msg_right_form">
        <el-form-item style="margin-left: 0px">
          <img src="../images/icon_msgInfo.png" alt="" />
        </el-form-item>
        <!-- 用户名 -->
        <el-form-item prop="loginName" error class="usernameBox" style="margin-left: 0px">
          <el-input type="text" v-model="ruleForm.loginName" disabled class="users">
            <i slot="prefix">
              <img
                class="header-icon"
                style="width: 19px; height: 23px; margin-top: 8px; margin-left: 4px"
                src="../images/icon_user.png"
                alt=""
              />
            </i>
            <!-- <template slot="prefix">
            <i slot="prefix" style="display:inline-block;height:40px;margin-right:15px">
              <img
                class="header-icon"
                style="width: 19px; height: 23px; margin-top: 8px; margin-left: 4px"
                src="../images/icon_user.png"
                alt=""
              />
            </i>
            <i slot="prefix" style="display:inline-block;height:40px;font-style: normal; font-size: 16px; vertical-align: top">当前用户：</i>
          </template> -->
          </el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item prop="value" error class="usernameBox" style="margin-left: 0px">
          <el-input
            type="text"
            v-model="ruleForm.value"
            placeholder=""
            disabled
            class="users"
          >
            <i slot="prefix">
              <img
                class="header-icon"
                style="width: 19px; height: 23px; margin-top: 8px; margin-left: 4px"
                src="../images/icon_msgPhone.png"
                alt=""
              />
            </i>
            <!-- <template slot="prefix">
            <i slot="prefix" style="display:inline-block;height:40px;margin-right:15px">
              <img
                class="header-icon"
                style="width: 19px; height: 23px; margin-top: 8px; margin-left: 4px"
                src="../images/icon_msgPhone.png"
                alt=""
              />
            </i>
            <i slot="prefix" style="display:inline-block;height:40px;font-style: normal; font-size: 16px; vertical-align: top">绑定号码：</i>
          </template> -->
          </el-input>
        </el-form-item>
        <!-- 短信验证码 -->
        <el-form-item prop="code" error class="usernameBox" style="margin-left: 0px">
          
          <el-input
            type="text"
            v-model="ruleForm.code"
            placeholder="请输入验证码"
            class="noUser"
            style="width: 60%; margin-right: 10px"
            maxlength="6"
            @keypress.enter.native="mobileCode()"
            @input="coderule(ruleForm.code)"
          >
            <i slot="prefix" class="loginName">
              <img
                class="header-icon"
                style="width: 19px; height: 23px; margin-top: 8px; margin-left: 4px"
                src="../images/icon_msgText.png"
                alt=""
              />
            </i>
            <i slot="prefix">
              <span style="font-style: normal; font-size: 16px; vertical-align: top">
              </span>
            </i>
          </el-input>
          <el-button v-if="!sendMsgDisabled" @click="note">获取验证码</el-button>
          <el-button v-else style="width: 36%">{{ time + "S" }}</el-button>
        </el-form-item>
        <el-form-item class="loginBtn">
          <div style="height: 40px">
            <el-button
              :type="contype"
              :disabled="confirm"
              @click="mobileCode()"
              style="
                width: 100%;
                height: 38px;
                font-size: 17px;
                border-radius: 6px;
                margin-top: 20px;
              "
              >确&emsp;认</el-button
            >
          </div>
        </el-form-item>
        <el-form-item class="msg msgInfo">
          <el-form-item class="msgTitle">
            <!-- <p class="platformName">{{ obj.playName }}</p> -->
            <!-- 提示语 -->
            <p :title="words" id="error" class="error errorInfo" v-show="markedWords">
              {{ words }}
            </p>
          </el-form-item>
        </el-form-item>
        <el-form-item class="bottomMsg">
          <a @click="backlogin">返回登录</a>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";
import utils from "../utils";
export default {
  props: {
    detailDatas: {
      type: Object,
      default: {},
    },
    playName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // 获取倒计时
      time: 60, // 发送验证码倒计时
      interval: "",
      sendMsgDisabled: false,
      ruleForm: {
        loginName: "",
        value: "",
        code: "",
        method: "",
        uuid: "",
      },
      words: "", //错误提示语
      markedWords: false,
      contype: "info",
      confirm: true,
      // contype: "primary",
      // confirm: false,
    };
  },
  watch: {
    detailDatas: function (newVal, oldVal) {
      console.log(newVal, "newVal");
      console.log(oldVal, "oldVal");
      if (newVal) {
        this.ruleForm.loginName = newVal.user;
        this.ruleForm.value = newVal.phone;
        this.ruleForm.uuid = newVal.uuid;
      }
    },
    deep: true,
  },
  mounted() {
    console.log(this.detailDatas.phone, "this.detailDatas.phonethis.detailDatas.phone");
    // this.confirm = false;
    // this.contype = "primary";
    this.confirm = true;
    this.contype = "info";
    this.ruleForm.value = this.detailDatas.phone;
    this.ruleForm.loginName = this.detailDatas.user;
    this.ruleForm.method = this.detailDatas.method;
    this.ruleForm.uuid = this.detailDatas.uuid;
    // 点击获取短信验证
  },

  methods: {
    backlogin() {
      this.confirm = true;
      this.contype = "info";
      // this.confirm = false;
      // this.contype = "primary";
      this.markedWords = false;
      this.time=0
      this.sendMsgDisabled = false;
      this.ruleForm.code = "";
      this.$emit("backlogin", 1); //1返回登录页面
    },
    // 获取验证码
    async note() {
      this.time=60
      this.count();
      var params = {
        type: 0, //序列号
        // value: '1861860187',
        // loginName: 'superemm',
        value: this.ruleForm.value,
        loginName: this.ruleForm.loginName,
      };
      let url = "/httpServe/rest/v6/get/sms/code";
      var ts, nonce;
      ts = "" + new Date().getTime();
      nonce = utils.common.randomStr(32);
      var sessid, sg;
      sessid = utils.sessid || "";
      sg = $.sign(ts, nonce, sessid, "");

      axios
        .post(url, params, {
          headers: {
            Auth: "web",
            sign: utils.common.sprintf(
              "ts=%s;nonce=%s;udid=%s;sg=%s",
              ts,
              nonce,
              sessid,
              sg
            ),
          },
        })
        .then((res) => {
          console.log(res, ".then.then.then");
        });
    },
    count() {
      if(this.time>0){
        this.sendMsgDisabled = true;
        this.time--
        setTimeout(this.count, 1000);
      }else{
        this.time=0
        this.sendMsgDisabled = false;
      }
    },
    coderule(e){
      console.log(e,'eeeeeeeeeeee');
      if(e!=''){
        this.confirm = false;
        this.contype = "primary";
      }else{
        this.confirm = true;
        this.contype = "info";
      }
    },
    // 校验手机验证码
    async mobileCode() {
      var params = {
        client: "web",
        user: this.ruleForm.loginName,
        // code:'254606',
        code: this.ruleForm.code,
        method: this.ruleForm.method,
        uuid: this.ruleForm.uuid,
      };
      const res = await this.$axios.post(
        "/httpServe/rest/v6/get/user/auth",
        params,
        true
      );
      this.$emit("method", params);
      console.log(this.ruleForm.code,'this.ruleForm.codethis.ruleForm.code');
      // if (this.ruleForm.code == "") {
      //   this.confirm = true;
      //   this.contype = "info";
      //   this.markedWords = true;
      //   window.clearInterval(this.interval);
      //   this.sendMsgDisabled = false;
      //   this.words = "请先获取验证码";
      //   return false;
      // }
      if (res.code == 20) {
        this.markedWords = true;
        this.words = "验证码错误";
        this.ruleForm.code = "";
        this.confirm = true;
        this.contype = "info";
        // window.clearInterval(this.interval);
        // this.sendMsgDisabled = false;
      }
      if (res.code == 21) {
        this.markedWords = true;
        console.log(res.message, "res.message");
        // this.words = res.message;
        this.words = "验证码错误";
        this.ruleForm.code = "";
        this.confirm = true;
        this.contype = "info";
      }
      if (res.data.method == -1) {
        this.sendMsgDisabled = false;
        this.$emit("backlogin", -1); //-1返回登录页面调用login获取用户信息进入SourceHome
        // this.$router.push('/SourceHome')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 30px;
}
.msg_bg {
  width: 1300px;
  height: 665px;
  background: url(../images/msgbg.png) center center no-repeat;
  background-size: 100% 100%;
  padding: 40px;
  // background-attachment:fixed;
  .msg_left_title {
    margin: 165px 0px 257px 0px;
    padding: 26px;
    p {
      color: white;
      text-align: center;
      font-size: 30px;
    }
  }
  .msg_right_form {
    padding: 15% 25% 10% 25%;
  }
}
::v-deep .user .el-input__inner {
  padding-left: 120px !important;
}
.bottomMsg a {
  color: #1e89e5;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.msgTitle {
  height: 62px;
  width: 330px;
  position: absolute;
  top: -10px;
  left: 90%;
  transform: translate(-50%);
}
.msgInfo .errorInfo {
  color: red !important;
  font-size: 12px;
}
</style>
